// react imorts
import { useState, useEffect } from "react";

// module imports
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";

// component imports
import MachineForm from "../../components/MachineForm";
import Graph from "../../components/Graph";
import Dropdown from "../../components/Dropdown";

// API imports
import { MachineInfoApi } from "./repository";
import { getDownloadURL } from "../../api/imageApi";

// filter options
const options = [
  { id: "TODAY", name: "Today" },
  { id: "WEEK", name: "Last 7 days" },
  { id: "MONTH", name: "Last 30 days" },
  { id: "YEAR", name: "Last 12 Months" },
];

export default function MachineInfo() {
  const { machineUuid } = useParams();
  const [machineInfo, setMachineInfo] = useState({});
  const [machineImage, setMachineImage] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [printCount, setPrintCount] = useState(0);
  const [filter, setFilter] = useState(options[0]);
  const [printCountToday, setPrintCountToday] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMachineInfo = async () => {
      const machineInfoResp = await MachineInfoApi.machineDetails(machineUuid);

      const printCount = await MachineInfoApi.getPrintCount(
        machineInfoResp.machine_uuid
      );

      setMachineInfo(machineInfoResp);
      setPrintCount(
        (printCount.prints_value || 0) -
          (machineInfoResp.initial_print_value || 0)
      );
      fetchImage(machineInfoResp);
      const printCountTodayResp =
        await MachineInfoApi.getMachineTelemetryLatest(machineUuid, filter);
      setPrintCountToday(printCountTodayResp.prints_value || 0);
    };
    fetchMachineInfo();
  }, [machineUuid]);

  const fetchImage = async (machine) => {
    if (machine["model"].model_image) {
      const image = await getDownloadURL(machine["model"].model_image);
      setMachineImage(image.url);
    } else {
      setMachineImage(
        `https://av-silkworm-machine-images-snd.s3.amazonaws.com/machine-images/roqprinteco.png`
      );
    }
  };

  return (
    <main className="flex-1 bg-gray-100">
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <button
            onClick={() => navigate("/machines")}
            className="flex flex-row justify-center items-center mb-2"
          >
            <ArrowLeftIcon
              className="h-5 w-5 align-middle mr-1"
              aria-hidden="true"
            />
            <p>{`Back to machines`}</p>
          </button>
          <h2 className="text-xl font-semibold text-gray-900">
            Machine Details
          </h2>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="flex flex-row">
              <div className="sm:w-[100%] mx-1">
                <div className="flex flex-col sm:flex-row w-full px-6 py-2 bg-white border-gray-200 shadow rounded-lg">
                  <img
                    className="sm:w-[43%] max-h-56 flex-shrink-0"
                    src={machineImage}
                    alt=""
                  />

                  <div className="py-1 sm:px-2 space-y-4 w-full">
                    <div className="flex flex-row justify-start">
                      <h3 className="text-xl font-semibold text-gray-900 mx-2">
                        {machineInfo.name}
                      </h3>
                      <div className="flex flex-row items-center justify-start mx-2">
                        <span className="flex h-3 w-3 relative mr-1">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75 "></span>
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                        </span>
                        <p className="text-sm font-medium text-primary relative">
                          {`Online`}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between sm:justify-start my-2">
                      <div className="mx-2">
                        <p>{printCount}</p>
                        <p className="text-xs text-[gray] text-left">
                          Print count
                        </p>
                      </div>
                      <div className="mx-2 sm:mx-8">
                        <p>First Floor</p>
                        <p className="text-xs text-[gray] text-left">
                          Location
                        </p>
                      </div>
                      <div className="mr-2 sm:mr-8">
                        <p>{machineInfo["model"]?.model_name}</p>
                        <p className="text-xs text-[gray] text-left">Model</p>
                      </div>
                    </div>
                    <div className="flex flex-row justify-start my-2">
                      <div className="mx-2">
                        <p>{machineInfo.machine_uuid}</p>
                        <p className="text-xs text-[gray] text-left">
                          Machine uuid
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex flex-row justify-end pt-4 cursor-pointer"
                      onClick={() => setOpenForm(true)}
                    >
                      <img src="/assets/editIcon.svg" alt="edit"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 bg-white border-gray-200 shadow rounded-lg pb-2 px-4 py-2">
              <div className="flex flex-col  justify-between p-3">
                <h2 className="text-xl font-semibold text-gray-900 ">
                  Telemetry
                </h2>
              </div>

              <p className="text-[gray] px-3 -mt-2">
                Your current activity and summary
              </p>
              <div className="grid sm:grid-cols-2 lg:flex  lg:flex-row justify-start pr-3 mx-3 my-4">
                <div className="px-2 py-1 lg:border-r-2 border-gray lg:pr-4">
                  <div className="flex flex-row justify-between py-2">
                    <p className="text-black font-medium text-base">Total</p>
                  </div>
                  <div className="text-[#00AAFF] font-normal text-2xl flex flex-row space-x-2">
                    <p>{printCount} </p>
                    <div className="flex flex-row justify-between items-center bg-[#DBF5CE] px-2 rounded-2xl max-w-min">
                      <svg
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="green"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.916504 4.24984L1.739 5.07234L4.4165 2.40067V11.8332H5.58317V2.40067L8.26067 5.07817L9.08317 4.24984L4.99984 0.166504L0.916504 4.24984Z"
                          fill="green"
                        />
                      </svg>
                      <p className="text-sm text-greenText mx-1">12%</p>
                    </div>
                  </div>
                </div>

                {/* TODO: This is the average aggregation to be implemented */}

                {/* <div className="lg:px-4 px-2 py-1 lg:border-r-2 border-gray">
                  <div className="flex flex-row justify-between py-2">
                    <p className="text-black font-medium text-base">Average</p>
                  </div>
                  <div className="text-[#00AAFF] font-normal text-2xl flex flex-row space-x-2">
                    <p>1313649 </p>
                    <div className="flex flex-row justify-between items-center bg-[#f5d8ce] px-2 rounded-2xl max-w-min">
                      <svg
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.0835 7.75016L8.261 6.92766L5.5835 9.59933L5.5835 0.166828H4.41683L4.41683 9.59933L1.73933 6.92183L0.916828 7.75016L5.00016 11.8335L9.0835 7.75016Z"
                          fill="red"
                        />
                      </svg>

                      <p className="text-sm text-red mx-1">12%</p>
                    </div>
                  </div>
                </div> */}

                <div className="lg:px-4 px-2  py-1 mr-4">
                  <div className="flex flex-row justify-between py-2">
                    <p className="text-black font-medium text-base">Today</p>
                  </div>
                  <div className="text-[#00AAFF] font-normal text-2xl flex flex-row space-x-2">
                    <p>{printCountToday} </p>
                    <div className="flex flex-row justify-between items-center bg-[#DBF5CE] px-2 rounded-2xl max-w-min">
                      <svg
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.916504 4.24984L1.739 5.07234L4.4165 2.40067V11.8332H5.58317V2.40067L8.26067 5.07817L9.08317 4.24984L4.99984 0.166504L0.916504 4.24984Z"
                          fill="green"
                        />
                      </svg>
                      <p className="text-sm text-greenText mx-1">12%</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-between items-center p-3">
                <div className="min-w-[148px] self-center">
                  {" "}
                  <Dropdown
                    filter={filter}
                    setFilter={setFilter}
                    options={options}
                  />
                </div>
              </div>

              <div>
                {machineInfo.machine_uuid && (
                  <Graph
                    machineUuid={machineInfo.machine_uuid}
                    filter={filter}
                  />
                )}
              </div>
            </div>
          </div>
          {/* /End replace */}
        </div>
        <MachineForm
          open={openForm}
          setOpen={setOpenForm}
          machineInfo={machineInfo}
          type="edit"
        ></MachineForm>
      </div>
    </main>
  );
}

// react imports
import React, { useState, useEffect, useContext } from "react";

// context imports

// module imports
import { Navigate, useLocation } from "react-router-dom";
import * as cognito from "../auth/cognito";

const AuthStatus = {
  Loading: "Loading",
  SignedIn: "SignedIn",
  SignedOut: "SignedOut",
};

const defaultState = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
};

export const AuthContext = React.createContext(defaultState);

export const AuthRequired = ({ children }) => {
  const { authStatus } = useContext(AuthContext);
  let location = useLocation();

  if (authStatus !== AuthStatus.SignedIn) {
    return <Navigate to="/signIn" state={{ from: location }} replace />;
  }

  return children;
};

export const AuthIsNotSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});

  async function getSessionInfo() {
    try {
      const session = await getSession();
      setSessionInfo({
        silkworm_alvyl_io_accessToken: session.accessToken.jwtToken,
        silkworm_alvyl_io_refreshToken: session.refreshToken.token,
        silkworm_alvyl_io_userName: session.accessToken.payload.username,
        silkworm_alvyl_io_idToken: session.idToken.jwtToken,
        silkworm_alvyl_io_email: session.idToken.payload.email,
        silkworm_alvyl_io_mobile: session.idToken.payload.phone_number,
      });
      window.localStorage.setItem(
        "silkworm_alvyl_io_idToken",
        `${session.idToken.jwtToken}`
      );

      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
    }
  }

  useEffect(() => {
    getSessionInfo();
  }, []);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithEmail(data, cb) {
    try {
      const res = await cognito.signInWithEmail(data.email, data.password);
      if (!res.newPasswordRequired) {
        setAuthStatus(AuthStatus.SignedIn);
      }
      getSessionInfo();
      cb(res);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      cb(err.message);
      throw err;
    }
  }

  async function newUserPassword(password, cb) {
    try {
      await cognito.newUserPassword(password);
      setAuthStatus(AuthStatus.SignedOut);
      cb();
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  function signOut() {
    cognito.signOut();
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function verifyCode(username, code) {
    try {
      await cognito.verifyCode(username, code);
    } catch (err) {
      throw err;
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession();
      return session;
    } catch (err) {
      throw err;
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes();
      return attr;
    } catch (err) {
      throw err;
    }
  }

  async function setAttribute(attr) {
    try {
      const res = await cognito.setAttribute(attr);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function sendCode(username) {
    try {
      const res = await cognito.sendCode(username);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(username, code, password) {
    try {
      await cognito.forgotPassword(username, code, password);
    } catch (err) {
      throw err;
    }
  }

  async function changePassword(oldPassword, newPassword) {
    try {
      await cognito.changePassword(oldPassword, newPassword);
    } catch (err) {
      throw err;
    }
  }

  const state = {
    authStatus,
    sessionInfo,
    // attrInfo,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    newUserPassword,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

// react imports
import { Fragment, useRef, useState, useEffect } from "react";

// module imports
import { Dialog, Transition } from "@headlessui/react";

// API imports
import { MachineApi } from "../../pages/machine/repository";
import { getUploadURL, uploadImage, getDownloadURL } from "../../api/imageApi";

export default function MachineForm({ open, setOpen, machineInfo, type }) {
  const cancelButtonRef = useRef(null);
  const initialFormData = Object.freeze({
    machineUuid: machineInfo?.machine_uuid || "",
    name: machineInfo?.name || "",
    initial_print_value: 0, // FIXME: this should be camelCase
    location: machineInfo?.location || "",
    model: {
      manufacturer_uuid: "b4cc0414-fb0e-4b46-9be2-6eaabf724e42",
      manufacturer_name: "ROQ",
      model_uuid: "09f54a2e-e4af-4dfa-8058-3ce3eeaf6280",
      model_name: "SROQ ECO",
      model_image: machineInfo?.model?.model_image || "",
    },
    ip: machineInfo?.ip || "",
    timing: {
      Treadle: 12,
      Cycle: "Sleep Cycle",
      Rotate: "Dont know",
    },
  });
  const [formData, updateFormData] = useState(initialFormData);
  const [image, setImage] = useState(null);

  useEffect(() => {
    updateFormData(initialFormData);
    getImageSource(type);
  }, [machineInfo]);

  // functions

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const machine = await MachineApi.createMachine(formData);
    setOpen(false);
  };

  const uploadMachineImageHandler = async (e) => {
    try {
      const imageUrlResp = await getUploadURL();
      const { key, url } = imageUrlResp;
      formData["model"].model_image = key;
      setImage(URL.createObjectURL(e.target.files[0]));
      await uploadImage(url, e.target.files[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const getImageSource = async (type) => {
    try {
      if (type === "edit") {
        const imageUrlResp = await getDownloadURL(
          machineInfo.model.model_image
        );
        setImage(imageUrlResp.url);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form
                className="space-y-8 divide-y divide-gray-200"
                onSubmit={handleSubmit}
              >
                <div className="space-y-8 divide-y divide-gray-200">
                  <div>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Machine Information
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        This information will be displayed on the Machine Info
                        page.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="machine-uuid"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Machine UUID
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="machineUuid"
                            id="machine-uuid"
                            autoComplete="machine-uuid"
                            className="flex-1 focus:ring-green-500 focus:border-green-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            onChange={handleChange}
                            defaultValue={machineInfo?.machine_uuid || ""}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="machine-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Machine Name
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="name"
                            id="machine-name"
                            autoComplete="machine-name"
                            className="flex-1 focus:ring-green-500 focus:border-green-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            onChange={handleChange}
                            defaultValue={machineInfo?.name || ""}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="machine-location"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Machine Location
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="location"
                            name="location"
                            maxLength={100}
                            rows={3}
                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            onChange={handleChange}
                            defaultValue={machineInfo?.location || ""}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="machine-ip-address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Machine IP-Address
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="ip"
                            id="machine-ip-address"
                            autoComplete="machine-ip-address"
                            className="flex-1 focus:ring-green-500 focus:border-green-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            onChange={handleChange}
                            defaultValue={machineInfo?.ip || ""}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="manufacturer"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Choose manufacturer
                        </label>
                        <div className="mt-1">
                          <select
                            id="manufacturer"
                            name="manufacturer"
                            autoComplete="manufacturer"
                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          >
                            <option defaultValue={true}>None</option>
                            <option selected={type === "edit"}>ROQ</option>
                          </select>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="machine-type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Choose Machine type
                        </label>
                        <div className="mt-1">
                          <select
                            id="machine-type"
                            name="machine-type"
                            autoComplete="machine-type"
                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          >
                            <option>None</option>
                            <option selected={type === "edit"}>
                              ROQPRINT YOU P18XL
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="initial-print-value"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Initial Print Value
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="initial_print_value"
                            id="initial-print-value"
                            autoComplete="initial-print-value"
                            className="flex-1 focus:ring-green-500 focus:border-green-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            onChange={handleChange}
                            defaultValue={
                              machineInfo?.initial_print_value || ""
                            }
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label
                          htmlFor="cover-photo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Uplaod machine image
                        </label>
                        <div className="mt-1 flex flex-col justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                          <div className="space-y-1 text-center">
                            {image ? (
                              <img src={image} alt="machineImage"></img>
                            ) : (
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}

                            <div className="flex justify-center text-sm text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500 text-center"
                              >
                                <span>Upload a file</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                  onChange={uploadMachineImageHandler}
                                  alt="machineImage"
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">
                              PNG, JPG up to 10MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      onClick={() => {
                        setOpen(false);
                        setImage(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { apiCall } from "../../api";

export const DeviceInfoApi = {
  async deviceDetails(deviceId) {
    const deviceInfo = await apiCall(`/devices/${deviceId}?details=all`);
    return deviceInfo;
  },

  async deleteDevice(deviceId) {
    const deviceInfo = await apiCall(`/devices/${deviceId}`, {}, "DELETE");
    return deviceInfo;
  },

  async updateDevice(deviceId, deviceData) {
    const device = await apiCall(`/devices/${deviceId}`, deviceData, "PATCH");
    return device;
  },
};
